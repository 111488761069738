.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    padding-top:20px;
    height: calc(100vh - 180px);
    overflow: auto;
}

.contentBlock{
    width: 50%;
    min-width: 400px;
}


.title{
    font-size: 30px;
}

.body{
    font-size: 18px;
}

.download{
    width: 100%;
    text-align: center;
}


.downloadButton{
    color: white;
    background-color: var(--ata-orange);
    font-size: 18px;
    padding: 10px;
    border-color: var(--ata-light-orange);
    align-content: center;
}

.downloadButton:hover{
    background-color: var(--ata-light-orange);
}
