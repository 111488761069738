.mainContainer{
    height: 100px;
    width: 100%;
}

.mainHeader{
    height: 90px;
    position: relative;
    display: grid;
    grid-template-areas:
      'ths nav ata';  
    grid-template-columns: 1px auto 230px;  
    width: 100%; 
    padding-right: 10px;
    padding-left: 10px;
    
}

.ataLogoDiv{
    grid-area: ata;
    height: 100%;
}

.thsLogoDiv{
    grid-area: ths;
    height: 100%;
}

.navigation{
    grid-area: nav;
    width: 100%;
}

.ataLogo{
    max-height: 100%; 
    max-width: 100%;
}



.thsLogo{
    max-height: 100%; 
    max-width: 100%;
}

.headerborder{
    height: 10px;
    background-color: var(--ata-orange);
}



.navigationGrid{
    display: flex;
    flex-direction: column;
    font-size: 15px;
    grid-area: nav;
    height: 100%;
}

.optionsGridContainer {
    position: relative;
    display: grid;
    grid-template-areas:
      'map calculator about';      
    height:100%; 
    background: transparent;
    margin-right: 50px;
    margin-left: 50px;
    cursor: pointer;
    justify-content: center;
    align-items: end;
    margin-bottom: 10px;
    font-size: large;
    }

.optionItemMap{
    grid-area: map;
}

.optionItemCalculator{
    grid-area: calculator;
    margin-left: 10px;
    margin-right: 10px;
    border-left: 2px solid black;
    border-right: 2px solid black;
    padding-left: 5px;
    padding-right: 5px;
}

.optionItemAbout{
    grid-area: about;
}

.selectedPath{
    color:  var(--ata-orange);
}
