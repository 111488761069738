.mainContainer{
    width: 300px;
    z-index: 1;
    background-color: var(--ata-orange);
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0.7;
    padding: 10px;
}

.dropDown{
    margin-bottom: 10px;
}

.headerText{
    font-size: large;
    font-weight: bold;
    margin-top: 10px;
}

.textTitle{
    font-weight: bold;
}

.text{
    font-size: medium;
}

.source{
    font-size: medium;
    margin-top: 10px;
}

.dropDownPanel{
    max-width: 500px;
}

.dropDownPanel :global(.p-dropdown-item){
    white-space: break-spaces;
}

