.mainContainer{
    position: absolute;
    bottom: 40px;
    right: 20px;
    border: 1px solid grey;
    padding: 10px;
    background-color: var(--ata-orange);
    opacity: 0.7;

    font-size: small;

    display: grid;
    grid-template-areas:
        'Legend'
        'Detail'; 
}

.legendItemSelection{
    grid-area: Selection;
    max-width: 400px;
    min-height: 10px;

   text-align: center;
}

.legendItemDetail{
    grid-area: Detail;
    display: flex;
    justify-content: center;
}

.legendItemLegend{
    grid-area: Legend;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid orange;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.legendValueFirst{
    margin-right: 10px;
}

.legendValueLast{
    margin-left: 10px;
}

.legendBox {
    font-size: 25px;
    margin:0;
    display: inline-block;
    width: 1em;
    height: 1em;
}
    