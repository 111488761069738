.mainGridContainer {
    position: relative;
    display: grid;
    grid-template-areas:
        'name'  
        'valueName'
        'value'; 
    grid-gap: 5px;
    border-color: lightgray;
}

.mainGridItemName{
    grid-area: name;
    font-size: medium;
    font-weight: bold;
}

.mainGridItemValueName{
    grid-area: valueName;
    font-size: small;
}

.mainGridItemValue{
    grid-area: value;
    font-size: small;
}

