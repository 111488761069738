@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital@0;1&family=Roboto:ital@0;1&display=swap');

:root {
  --ata-orange:#F47E14;
  --ata-yellow: #FCC249;
  --ata-green: #9FBF60;
  --ata-dark-gray: #54575B;
  --ata-dark-blue: #315499;
  --ata-light-orange: #FFB077;
}

.mainContainer {
  font: normal 10px 'Roboto', 'Merriweather', sans-serif;
}

.mainHeader{
  width: 100%;
  height: 100px;
}

.mainBody{
  position: relative;
  height: 100%;
}

.mainFooter{
  height: 80px;
}

.p-dropdown{
  width: 100%;
}

.mapbox-logo{
  display: none !important;
}