.mainFooter{
    background-color: var(--ata-orange);
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.text{
    color: white;
    font-size: large;
    margin-right: 20px;
}

.thsLogo{
    height: 90%;
    margin-top: 3px;
}