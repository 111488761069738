.accordionHeader{
    font-size: xx-large;
}

.accordionHeader :global(.p-accordion-header-link){
    background-color: var(--ata-orange) !important;
    color: white !important;
}

.calculatorInputGrid{
    display: grid;
    grid-template-areas:
     'inputText inputText inputText inputText inputText'
      'telehealthHeader telehealthHeader telehealthHeader telehealthHeader telehealthHeader'
      'solutionCostTitle pmptyCostTitle empty empty empty'
      'solutionCost pmptyCost empty empty empty'
      'demographicHeader demographicHeader demographicHeader demographicHeader demographicHeader'
      'totalPopulationTitle diseaseImpactTitle noInternetTitle slowConnectionTitle dataSubsidyTitle'
      'totalPopulation diseaseImpact noInternet slowConnection dataSubsidy'
      'esvaHeader esvaHeader esvaHeader esvaHeader esvaHeader'
      'EconomicBurdenTitle qalyTitle empty2 empty2 empty2'
      'EconomicBurden qaly empty2 empty2 empty2';
      column-gap: 5px;
      font-size: small;
}

.calculatorHeaderItem{
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bolder;
    font-size: large;
}

.calculatorItemInputText{
    grid-area: inputText;
}

.calculatorItemTelehealthHeader{
    grid-area: telehealthHeader;
}

.calculatorItemDemographichHeader{
    grid-area: demographicHeader;
}

.calculatorItemEsvaHeader{
    grid-area: esvaHeader;
}


.calculatorItemTotalPopulation{
    grid-area: totalPopulation;
}

.calculatorItemTotalPopulationTitle{
    grid-area: totalPopulationTitle;
}

.calculatorItemDiseaseImpact{
    grid-area: diseaseImpact;
}

.calculatorItemDiseaseImpactTitle{
    grid-area: diseaseImpactTitle;
}

.calculatorItemNoInternet{
    grid-area: noInternet;
}

.calculatorItemNoInternetTitle{
    grid-area: noInternetTitle;
}


.calculatorItemSlowConnection{
    grid-area: slowConnection;
}

.calculatorItemSlowConnectionTitle{
    grid-area: slowConnectionTitle;
}

.calculatorItemDataSubsidy{
    grid-area: dataSubsidy;
}

.calculatorItemDataSubsidyTitle{
    grid-area: dataSubsidyTitle;
}

.calculatorItemSolutionCost{
    grid-area: solutionCost;
}

.calculatorItemSolutionCostTitle{
    grid-area: solutionCostTitle;
}

.calculatorItemPmpty{
    grid-area: pmptyCost;
}

.calculatorItemPmptyTitle{
    grid-area: pmptyCostTitle;
}

.calculatorItemEconomicBurden{
    grid-area: EconomicBurden;
}

.calculatorItemEconomicBurdenTitle{
    grid-area: EconomicBurdenTitle;
}

.calculatorItemQaly{
    grid-area: qaly;
}

.calculatorItemQalyTitle{
    grid-area: qalyTitle;
}



.calculatorInputItem{
    margin-right: 10px;
    width: 250px;
}

.calculatorContainer{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;    
    margin-bottom: 20px;
}

.cardContainer{
    display: flex;
    flex-wrap: wrap;
    width: 1020px;
}

.card{
    max-width: 500px;
    min-width: 500px;
    margin-top: 10px;
    margin-left: 10px;
    background-color:var(--ata-orange) !important;
    color: white !important;
}


.stakeHolderCostCard{
    background-color:var(--ata-orange)  !important;
    background-image: linear-gradient(to top left, white, var(--ata-light-orange) , var(--ata-light-orange), var(--ata-light-orange), var(--ata-light-orange)) !important;
}

.stakeHolderSavingsCard{
    background-color:var(--ata-yellow)  !important;
    background-image: linear-gradient(to top left, white, var(--ata-yellow) , var(--ata-yellow), var(--ata-yellow), var(--ata-yellow)) !important;
}

.stakeHolderQalyCard{
    background-color:var(--ata-green)  !important;
    background-image: linear-gradient(to top left, white, var(--ata-green) , var(--ata-green), var(--ata-green), var(--ata-green)) !important;
}

.cardItemCalculated{
    margin-bottom: 5px;
    font-weight:  bold
}

.cardItemInput{
    margin-bottom: 5px;
}

.chartCard{
    width: 1010px;
    margin-top: 10px;
    margin-left: 10px;
}

.infoCard{
    width: 1010px;
    margin-top: 10px;
    margin-left: 10px;
    font-size: medium;
}

.chartToolTip{
    background-color: white;
    border: 1px  var(--ata-light-orange) solid;
    padding-left: 5px;
    padding-right: 5px;
    font-size: small;
}

.chartToolTipHeader{
    font-size: medium;
    font-weight: bold;
}


@media screen and (max-width: 1036px){
    .cardContainer{
        width: 510px;
    }

    .chartCard{width: 500px;}
    
    .infoCard{width: 500px;}

  
    .calculatorInputGrid{
        grid-template-areas:
        'inputText inputText inputText'
        'telehealthHeader telehealthHeader telehealthHeader'
        'solutionCostTitle pmptyCostTitle empty'
        'solutionCost pmptyCost empty'
        'demographicHeader demographicHeader demographicHeader'
        'totalPopulationTitle diseaseImpactTitle noInternetTitle'
        'totalPopulation diseaseImpact noInternet'
        'slowConnectionTitle dataSubsidyTitle empty2'
        'slowConnection dataSubsidy empty2'
        'esvaHeader esvaHeader esvaHeader'
        'EconomicBurdenTitle qalyTitle empty3'
        'EconomicBurden qaly empty3';
          column-gap: 5px;
    
    }

    .calculatorItemSlowConnectionTitle{
        margin-top: 10px;
    }
    .calculatorItemDataSubsidyTitle{
        margin-top: 10px;
    }
}

@media screen and (max-width: 670px){
    .calculatorInputGrid{
        grid-template-areas:
        'inputText inputText'
        'telehealthHeader telehealthHeader'
        'solutionCostTitle pmptyCostTitle'
        'solutionCost pmptyCost'
        'demographicHeader demographicHeader'
        'totalPopulationTitle diseaseImpactTitle'
        'totalPopulation diseaseImpact'
        'noInternetTitle slowConnectionTitle'
        'noInternet slowConnection'
        'dataSubsidyTitle empty2'
        'dataSubsidy empty2'
        'esvaHeader esvaHeader'
        'EconomicBurdenTitle qalyTitle'
        'EconomicBurden qaly';
          column-gap: 5px;
    
    }
}

.chartContainer{
    width: 100%;
    height: 100%;
}